<template>
  <v-main style="background-color:#bee3f5;">
    <v-container >
        <v-card
            v-if="this.is_form_show"
            class="bl_contactCard"
            elevation="5"
            max-width="600"
            style="margin-top:50px; margin-bottom:50px; padding: 32px 16px;"
        >
            <v-card-title>お問い合わせ</v-card-title>
            <v-card-text>
                お仕事のご相談、お見積もり依頼、応援コメント お待ちしております！
            </v-card-text>
            <v-card-text>
                <v-form
                    action="https://docs.google.com/forms/u/6/d/e/1FAIpQLSf4AoDv87r2Csni_ww5oXgndDjOsIh-IXtcIKmqZVPh6WaTzQ/formResponse"
                    method="post" target="dummyIframe" name="myform"
                    id="my_form"
                    >
                        <v-col
                        cols="12"
                        sm="6"
                        >
                            <v-text-field
                                v-model="input_name"
                                name="entry.1128411514"
                                label="お名前 your name"
                                :rules="nameRules"
                                placeholder="姓名、SNSアカウント名など"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col
                        cols="12"
                        sm="6"
                        >
                            <v-text-field
                                v-model="input_mail"
                                name="entry.866624704"
                                label="メールアドレス mail"
                                :rules="emailRules"
                                placeholder="name@email.address"
                                type="email"
                                required
                            ></v-text-field>
                        </v-col>

                        <v-col
                        cols="12">
                        <v-textarea
                            v-model="input_text"
                            name="entry.1779154098"
                            label="メッセージ message"
                            auto-grow
                            value="お問い合わせ内容を入力してください。"
                        ></v-textarea>
                    </v-col>

                    <v-card-actions class="bl_buttonWrapper">
                        <v-btn
                        @click.prevent="sendPre"
                        :disabled="!input_name || !input_mail || !input_text"
                            type="submit" name="submit_button" value="送信する" color="primary"
                        >
                            送信する
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card-text>
        </v-card>
        <v-card
            v-if="!this.is_form_show"
            class="bl_contactCard"
            elevation="5"
            max-width="600"
            style="margin-top:50px; margin-bottom:50px; padding: 32px 16px;"
        >
            <v-card-title>お問い合わせ</v-card-title>
            <v-card-text>
              <p>お問い合わせありがとうございます。確認し返信いたします。</p>
              <p>
                  返信が遅い場合は届いていない可能性がございます。お手数ですが、info[at]interemit.com までご連絡いただければ幸いです。
              </p>
            </v-card-text>
        </v-card>

    </v-container>
    <iframe name="dummyIframe" style="display:none;"></iframe>
</v-main>
</template>

<script>

  export default {
    name: 'contact',
    components: {

    },
    methods:{
        sendPre(){
            if(this.input_name && this.input_mail && this.input_text){

                document.myform.submit();
                this.is_form_show = false
            }

        }
    },
    data(){
      return{
        is_form_show: true,
        input_name: '',
        input_mail: '',
        input_text: '',
        nameRules: [
            v => !!v || 'お名前は必須項目です',
        ],
        email: '',
        emailRules: [
            v => !!v || 'メールアドレスは必須項目です',
            v => /.+@.+/.test(v) || 'メールアドレスの形式が間違っているようです',
        ],
      }
    }
  }
</script>

<style scoped>
</style>
